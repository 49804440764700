import React, { lazy, Suspense } from 'react'
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom'

//context
import { UserProvider } from './context/user/userContext'

//route
import ProtectedRoute from './components/ProtectedRoute'
// VDS
import { VDSManager } from '@vds/utilities'
import { Loader } from '@vds/loaders'

const Home = lazy(() => import('./components/pages/home'))
const PageNotFound = lazy(() => import('./components/pages/PageNotFound'))
// apps
const AppDetails = lazy(() => import('./components/pages/apps/AppDetails'))
const NorthBound = lazy(() => import('./components/pages/northBound/NorthBoundUpload'))
const Apps = lazy(() => import('./components/pages/apps/Apps'))
// teams
const Teams = lazy(() => import('./components/pages/teams/Teams'))
// categories
const Categories = lazy(() =>
  import('./components/pages/products/categories/Categories')
)
const CategoryDetails = lazy(() =>
  import('./components/pages/products/categories/CategoryDetails')
)
// products

const ProductDetails = lazy(() =>
  import('./components/pages/products/ProductDetails')
)

const ProductSpec = lazy(() =>
  import('./components/pages/products/ProductSpec')
)
const SpecView = lazy(() => import('./components/pages/products/SpecView'))

// documentation
const Help = lazy(() => import('./components/pages/documentation/HelpNoForm'))
const FAQ = lazy(() => import('./components/pages/documentation/FAQ'))
const GetStarted = lazy(() =>
  import('./components/pages/documentation/GetStarted')
)

const App = () => {
  return (
    <VDSManager>
      <UserProvider>
        <Suspense
          fallback={<Loader active={true} fullscreen={false} surface="light" />}
        >
          <Router>
            <Routes>
              {/****=================================== Route Section START ============================================== ***/}

              {/****=================================== Apps Section =================================================== ***/}
              <Route
                element={
                  <ProtectedRoute>
                    <AppDetails />
                  </ProtectedRoute>
                }
                path="/apis/sec/apps/app"
              />
              <Route
                element={
                  <ProtectedRoute>
                    <NorthBound />
                  </ProtectedRoute>
                }
                path="/apis/sec/apps/app/northBound-upload"
              /> 
              <Route
                element={
                  <ProtectedRoute>
                    <Apps />
                  </ProtectedRoute>
                }
                path="/apis/sec/apps"
              />
              {/****=================================== Teams Section ================================================== ***/}
              <Route
                element={
                  <ProtectedRoute>
                    <Teams />
                  </ProtectedRoute>
                }
                path="/apis/sec/teams"
              />
              {/***=================================== Products Section ================================================ ***/}
              <Route element={<Categories />} path="/apis/ns/products" />
              <Route
                element={<CategoryDetails />}
                path="/apis/ns/products/:categoryKey"
              />
              <Route
                element={<ProductDetails />}
                path="/apis/ns/products/:categoryKey/:productKey"
              />
              <Route
                element={<ProductDetails />}
                path="/apis/sec/products/:categoryKey/:productKey"
              />
              {/* <Route
                element={<ProductSpec />}
                path="/apis/ns/products/:categoryKey/:productKey/:fileName/specification"
              /> */}
              <Route
                element={<ProductSpec />}
                path="/apis/sec/products/:categoryKey/:productKey/:fileName/specification"
              />
              {/* <Route
                element={<SpecView />}
                path="/apis/ns/products/:categoryKey/:productKey/:fileName/specification/view"
              /> */}
              <Route
                element={<SpecView />}
                path="/apis/sec/products/:categoryKey/:productKey/:fileName/specification/view"
              />

              {/***=================================== Documentation Section =============================================== ***/}
              <Route
                element={<GetStarted />}
                path="/apis/ns/documentation/get-started"
              />
              <Route
                element={<FAQ />}
                path="/apis/ns/documentation/frequently-asked-questions"
              />
              {/***=================================== Other Section ======================================================= ***/}
              <Route element={<Help />} path="/apis/ns/documentation/help" />
              <Route path="/apis/ns" element={<Home />} />
              <Route element={<PageNotFound />} path="/page-not-found" />
              <Route path="/" element={<Navigate to="/apis/ns" />} />
              <Route element={<Navigate to="/page-not-found" />} path="*" />

              {/****=================================== Route Section END ============================================== ***/}
            </Routes>
          </Router>
        </Suspense>
      </UserProvider>
    </VDSManager>
  )
}
export default App
